import React from "react"
import { Link } from "gatsby"
import Header from "../components/header/header"
import "../../styles/service.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

const Services = ({ data }) => (
  <Layout>
    <Header />
    <SEO
      title="Services | Event Planning Made Easy"
      description="YESInspired Events specializes in experimental social and corporate event planning services. We work with clients on a range of events, including small private parties to large philanthropic galas. YES Inspired Events is dedicated to providing exceptional service and sophisticated, yet tasteful and fun gatherings."
    />
    <div className="services-page-container">
      <Fade>
        <h2 className="services-page-sub-heading">Our specialty</h2>
        <h1 className="services-page-heading">The Services</h1>
        <p className="services-page-body">
          Event coordinators, Stephanie & Ysabel’s skills in event logistics,
          design & management ensure that each aspect of your event is handeled
          with care. From budget management and tracking payments to a
          personalized planning schedule, design concept planning, vendor and
          event planning management, our services assist in the uniqueness of
          your event.
        </p>
      </Fade>
      <div className="service-component-list">
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image"
            fluid={data.weddingService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper">
            <Fade>
              <h3 className="service-component-heading">Weddings</h3>
              <h4 className="service-component-sub-heading">
                Once in a lifetime
              </h4>
              <div className="service-component-line wedding-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                We help you find your dream venue, create a stunning décor
                scheme, then handle the hiring, negotiation and management of
                all your vendors.
                <br></br>
                <br></br>
                From budget management and tracking payments to a personalized
                planning schedule, our wedding services have all the help you
                need to make wedding planning manageable and enjoyable.
              </p>
              <Link
                to="/services/wedding-packages"
                className="service-component-link"
              >
                <button className="service-component-button wedding-component">
                  Explore Packages
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image flip-1"
            fluid={data.eventsService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper flip-2">
            <Fade>
              <h3 className="service-component-heading">Events</h3>
              <h4 className="service-component-sub-heading">
                Make it memorable
              </h4>
              <div className="service-component-line event-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                We help you find your dream venue, create a stunning décor
                scheme, then handle the hiring, negotiation and management of
                all your vendors.
                <br></br>
                <br></br>
                From budget management and tracking payments to a personalized
                planning schedule, our wedding services have all the help you
                need to make wedding planning manageable and enjoyable.
                <br></br>
                <br></br>
                Here are a few examples of the types of events we have curated:
                <br></br>
                <div className="event-examples-wrapper">
                  <ul className="event-examples">
                    <li>Baby Showers</li>
                    <li>Trade shows</li>
                    <li>Workshops</li>
                    <li>Corporate Functions</li>
                  </ul>
                  <ul className="event-examples">
                    <li>Engagement parties</li>
                    <li>Proposals</li>
                    <li>Holiday Parties</li>
                    <li>Debuts</li>
                  </ul>
                </div>
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button event-component ">
                  Get this Package
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image"
            fluid={data.designService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper">
            <Fade>
              <h3 className="service-component-heading">Design</h3>
              <h4 className="service-component-sub-heading">Inspired Décor</h4>
              <div className="service-component-line design-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                For those who love our aesthetic but are planning their own
                wedding, or working with another planner, we offer complete
                event design on its own.
                <br></br>
                <br></br>A detailed, visual wedding décor design complete with
                vendor suggestions that fit within your budget, hand-drawn
                mockups, and a mood board will give you a thorough look at how
                your wedding will come to life. On top of this detailed design,
                we’ll help you handcraft the most fabulous décor, and thrift
                high and low for that perfect vintage statement piece.
                <br></br>
                <br></br>
                We have created some small packages to help bring your vision to
                reality on your wedding day:
                <div className="design-package-container">
                  <div className="design-package-wrapper">
                    <h4>Package A </h4>
                    <ul>
                      <li>Mood/Story Board</li>
                      <li> 2 Consultations</li>
                      <li>Vendor referrals</li>
                    </ul>
                    <span>$300 + HST</span>
                  </div>
                  <div className="design-package-wrapper">
                    <h4>Package B</h4>
                    <ul>
                      <li>Mood/Story Board</li>
                      <li> Design Concept</li>
                      <li> 3 Consultations</li>
                      <li>Vendor referrals</li>
                    </ul>
                    <span>$600 + HST</span>
                  </div>
                </div>
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button design-component">
                  Get this Package
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image flip-1"
            fluid={data.consultService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper flip-2">
            <Fade>
              <h3 className="service-component-heading">Consultation</h3>
              <h4 className="service-component-sub-heading">
                Perfectly tailored
              </h4>
              <div className="service-component-line consult-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                Wherever you might be in the wedding planning process, our
                consulting services will provide you with professional
                assistance when you need it.
                <br></br>
                <br></br>
                Whether you are looking for vendor referrals, theme design,
                timeline assistance, or simply need advice, we can provide you
                with all the information you need
                <br></br>
                <br></br>
                Our consultation packages start at:<br></br>
                <ul className="consult-span-wrapper">
                  <li className="consult-span-item">
                    <span className="consult-span">1 hour(s)</span> - $75 + HST
                  </li>
                  <li className="consult-span-item">
                    <span className="consult-span">3 hour(s)</span> - $200 + HST
                  </li>
                  <li className="consult-span-item">
                    <span className="consult-span">6 hour(s)</span> - $400 + HST
                  </li>
                </ul>
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button consult-component">
                  Get this Package
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image"
            fluid={data.boxService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper">
            <Fade>
              <h5 className="service-component-new design-new">New!</h5>
              <h3 className="service-component-heading">Bridal Boxes</h3>
              <h4 className="service-component-sub-heading">
                Personally Curated
              </h4>
              <div className="service-component-line design-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                Our customizable gift boxes are perfect for bridesmaids &
                groomsmens gifts or proposals. For more information please fill
                out our contact form below and we will get back to you with the
                full details.
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button design-component">
                  Get this Package
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image flip-1"
            fluid={data.microService.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper flip-2">
            <Fade>
              <h5 className="service-component-new">New!</h5>
              <h3 className="service-component-heading">Micro Weddings</h3>
              <h4 className="service-component-sub-heading">
                Intimately Yours
              </h4>
              <div className="service-component-line wedding-component-line">
                &nbsp;
              </div>
              <p className="service-component-body">
                Our all inclusive wedding packages include venue, Month of
                coordination, on site coordination, pre planning, design &
                decor, florals, rentals, Food & Beverage, cake, hair & makeup +
                Photography for up to 50 people. Kindly note that some packages
                are more all inclusive than others as each venue has specific
                inclusions for wedding parties. All Micro packages can also be
                customized to fit your budget and include your favorite vendors.
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button wedding-component">
                  Get this Package
                </button>
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Services

export const serviceQuerry = graphql`
  query {
    weddingService: file(relativePath: { eq: "service-page-weddings.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eventsService: file(relativePath: { eq: "service-page-events.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    designService: file(relativePath: { eq: "service-page-design.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consultService: file(relativePath: { eq: "service-page-consult.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boxService: file(relativePath: { eq: "service-page-boxes.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microService: file(relativePath: { eq: "service-page-micro.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
