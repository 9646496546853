import { Link } from "gatsby"
import MobileNav from "./mobile-header"
import PropTypes from "prop-types"
import React from "react"
import mainLogo from "../../images/main_logo.svg"
import "../../../styles/header-styles.scss"

const Header = ({ siteTitle }) => (
  <header>
    <MobileNav />
    <ul className="nav-list">
      <li className="nav-item">
        <Link to="/about" className="nav-link">
          About
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/services" className="nav-link">
          Services
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/gallery" className="nav-link">
          Gallery
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/" className="nav-link">
          <img alt="main logo" className="main-logo" src={mainLogo} />
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/bridal-boxes" className="nav-link">
          Bridal Box
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/vendors" className="nav-link">
          Vendors
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/contact" className="nav-link">
          Contact
        </Link>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
